import { type ReactNode } from 'react'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import Photo from './photo'

interface HeroLogoProps {
  logo: SanityImageFragment
  children: ReactNode
}

const HeroLogo = ({ logo, children }: HeroLogoProps) => {
  return (
    <div className="relative">
      <div
        id="hero-logo"
        className="absolute z-[1] top-1/3 bottom-0 inset-x-0 pointer-events-none"
      >
        <Photo
          image={logo}
          className="sticky top-[var(--headerHeight)] pt-10 pb-5 max-w-[80%] mx-auto"
          imageClassName="w-full"
          showPlaceholder={false}
        />
      </div>
      {children}
    </div>
  )
}

export default HeroLogo
